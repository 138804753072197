const Domains  = [
  {
    domain: ['ae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['ae-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['auh.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['auh-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['dxb.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['dxb-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-ae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-auh.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-dxb.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-uae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['uae.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['uae-en.goshopright.com'],
    code: 'AE'
  },
  {
    domain: ['en-ksa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['en-sa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['en-sar.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['en-saudi.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['ksa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['ksa-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sa.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sa-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sar.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['sar-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['saudi.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['saudi-en.goshopright.com'],
    code: 'SA'
  },
  {
    domain: ['canada.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['can.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['ca.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['en-canada.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['en-can.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['en-ca.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['canada-en.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['can-en.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['ca-en.goshopright.com'],
    code: 'CA'
  },
  {
    domain: ['kuwait.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kw.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwd.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwt.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kuwait.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kw.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kwd.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['en-kwt.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kuwait-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kw-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwd-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['kwt-en.goshopright.com'],
    code: 'KW'
  },
  {
    domain: ['oman.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['muscat.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['om.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['oma.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omn.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omr.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-oman.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-muscat.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-om.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-oma.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-omn.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['en-omr.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['oman-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['muscat-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['om-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['oma-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omn-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['omr-en.goshopright.com'],
    code: 'OM'
  },
  {
    domain: ['uk.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gb.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbr.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbp.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['eng.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['unitedkingdom.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['britain.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['british.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['england.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-uk.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-gb.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-gbr.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-gbp.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-eng.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-unitedkingdom.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-britain.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-british.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['en-england.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['uk-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gb-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbr-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['gbp-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['eng-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['unitedkingdom-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['britain-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['british-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['england-en.goshopright.com'],
    code: 'GB'
  },
  {
    domain: ['bahrain.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bh.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bhd.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['en-bahrain.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['en-bh.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['en-bhd.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bahrain-en.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bh-en.goshopright.com'],
    code: 'BH'
  },
  {
    domain: ['bhd-en.goshopright.com'],
    code: 'BH'
  }
];

export default Domains;