const goShopCate_a = [
  {
    link: 'beauty',
    name: 'beauty',
    image: 'https://cdn.ebtida.com/Beauty/Beauty-Giftsets.jpg'
  },
  {
    link: 'gift sets',
    name: 'gift_sets',
    image: 'https://cdn.ebtida.com/Beauty/Gift-sets.jpg'
  },
  {
    link: 'haircare',
    name: 'haircare',
    image: 'https://cdn.ebtida.com/Beauty/haircare.jpg'
  },
  {
    link: 'wipes',
    name: 'wipes',
    image: 'https://cdn.ebtida.com/Beauty/Wipes.jpg'
  },
  {
    link: 'makeup',
    name: 'makeup',
    image: 'https://cdn.ebtida.com/Beauty/makeup.jpg',
  },
  {
    link: 'mens grooming',
    name: 'mens_grooming',
    image: 'https://cdn.ebtida.com/Beauty/mens-grooming.jpg'
  },
  {
    link: 'board games',
    name: 'Board Games',
    image: 'https://cdn.ebtida.com/1706732527625-Board-Games-x.png'
  },
  {
    link: 'skincare',
    name: 'skincare',
    image: 'https://cdn.ebtida.com/Beauty/skincare.jpg'
  }
]


const goShopCM = [
  {
    link: 'makeup',
    image: 'https://cdn.ebtida.com/1708632868222-Makeup-1.jpg'
  },
  {
    link: 'health and nutrition',
    image: 'https://cdn.ebtida.com/1704886728088-Health-and-Nutrition.jpg'
  },
]

const goShopCate_b = [
  {
    link: 'andriod phone',
    name: 'andriod_phone',
    image: 'https://cdn.ebtida.com/Electronics/Andriod-phone.jpg'
  },
  {
    link: 'gaming accessories',
    name: 'gaming_accessories',
    image: 'https://cdn.ebtida.com/Electronics/gaming-accessories.jpg'
  },
  {
    link: 'gaming console',
    name: 'gaming_console',
    image: 'https://cdn.ebtida.com/Electronics/gaming-console.jpg'
  },
  {
    link: 'gaming sets',
    name: 'gaming_sets',
    image: 'https://cdn.ebtida.com/Electronics/gaming-set-ups.jpg'
  },
  {
    link: 'headphones',
    name: 'headphones',
    image: 'https://cdn.ebtida.com/Electronics/headphones.jpg',
  },
  {
    link: 'laptops',
    name: 'laptops',
    image: 'https://cdn.ebtida.com/Electronics/laptop.jpg'
  }
]


const goShopCate_c = [
  {
    link: 'healthcare',
    name: 'healthcare',
    image: 'https://cdn.ebtida.com/1704374368517-Healthcare-1.png'
  },
  {
    link: 'watches',
    name: 'watches',
    image: 'https://cdn.ebtida.com/1704374368517-watches-1.png'
  },
  {
    link: 'speakers',
    name: 'speakers',
    image: 'https://cdn.ebtida.com/1704482914220-Speakers-2.png'
  },
  {
    link: 'music instruments',
    name: 'music_instruments',
    image: 'https://cdn.ebtida.com/1704374368517-Music-Instruments.png'
  },
  {
    link: 'makeup',
    name: 'makeup',
    image: 'https://cdn.ebtida.com/1704374368517-Makeup-1.png',
  },
  {
    link: 'gaming console',
    name: 'gaming_console',
    image: 'https://cdn.ebtida.com/1704374368517-Gaming-Consoles-01.png'
  }
]


const goShopFashion = [
  {
    link: 'jacket',
    name: 'jackets',
    image: 'https://cdn.ebtida.com/1704911108306-Jackets.jpg'
  },
  {
    link: 'boot',
    name: 'boots',
    image: 'https://cdn.ebtida.com/1704911108306-Boots.jpg'
  },
  {
    link: 'hoodies and sweatshirts',
    name: 'hoodies_and_sweat_shirts',
    image: 'https://cdn.ebtida.com/1704911108306-Hoodies.jpg'
  },
  {
    link: 'jeans',
    name: 'jeans',
    image: 'https://cdn.ebtida.com/1704911108306-Jeans.jpg'
  },
  {
    link: 't-shirt',
    name: 'tshirts',
    image: 'https://cdn.ebtida.com/1704911108306-T-SHirts.jpg',
  },
  {
    link: 'women dress',
    name: 'women_dress',
    image: 'https://cdn.ebtida.com/1704482792549-Ladies-Dress.jpg'
  }
]


const category_a = goShopCate_a
const category_b = goShopCate_b
const category_c = goShopCate_c
const fashion = goShopFashion
const carousel = goShopCM

const HomeData = {
  category_a: category_a,
  category_b: category_b,
  category_c: category_c,
  fashion: fashion,
  carousel: carousel
}


export default HomeData;