// import { Helmet } from "react-helmet";
import { Helmet } from 'react-helmet-async';
import Host from "./Host";

export default function HelmetComponent({ title }) {
  return (
    <Helmet>
      <title>{`${title ? title : ''} ${title && '|'} ${Host?.metaTitle}`}</title>
    </Helmet>
  )
}
