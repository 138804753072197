const goShopImages = [
  {
    link: 'makeup',
    image: 'https://cdn.ebtida.com/1702926227089-Makeup.jpg'
  },
  {
    link: 'beauty',
    image: 'https://cdn.ebtida.com/1702926258508-Haircare--(Beauty).jpg'
  },
  {
    link: 'appliances',
    image: 'https://cdn.ebtida.com/1704888536066-styling.jpg'
  }
]

  const domain = 'goshopright.com'
  const domainName = 'Goshopright'
  const domainLogo = 'https://cdn.ebtida.com/1703017248524-32(1).png'
  const bgColor = '#e0e9d2'
  const favicon = 'https://cdn.ebtida.com/1703526901758-Favicon.png'
  const email = 'support@goshopright.com'
  const metaTitle = 'Discover Exclusive International Brands at GoShopRight.com - Your Ultimate E-commerce Destination.'
  const metaDescription = 'Discover an unparalleled shopping experience at goshopright.com. Explore our vast selection of collections sourced from international vendors, all at unbeatable prices. Shop now and find incredible bargains on the latest trends and must-have items.'
  const headerBg = '#e0e9d2'
  const subMenu = '#fff'
  const bannesImages = goShopImages
  const buttonColor = '#030303'
  const address = 'Fahidi Towers, 1502A, 15th Floor <br />Bur Dubai, Khalid Bin Waleed Road,<br /> Dubai, UAE'
  const phone = '+971 4 836 9557'

const Host = {
  domain: domain,
  domainName: domainName,
  domainLogo: domainLogo,
  bgColor: bgColor,
  favicon: favicon,
  email: email,
  metaTitle: metaTitle,
  metaDescription: metaDescription,
  headerBg: headerBg,
  subMenu: subMenu,
  bannesImages: bannesImages,
  buttonColor: buttonColor,
  address: address,
  phone: phone
}


export default Host;